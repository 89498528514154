:root {
  --yellow: #ffe03d;
  --slate: #7184a5;
  --purple-slate: #545375;
  --lemon-green: #32e0c4;
  --salmon: #ff9595;
  --trans: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}

.dark-cross-dots {
  background: radial-gradient(#fff 3px, transparent 4px),
    radial-gradient(#fff 3px, transparent 4px),
    linear-gradient(#303030 4px, transparent 0),
    linear-gradient(
      45deg,
      transparent 74px,
      transparent 75px,
      #a4a4a4 75px,
      #a4a4a4 76px,
      transparent 77px,
      transparent 109px
    ),
    linear-gradient(
      -45deg,
      transparent 75px,
      transparent 76px,
      #a4a4a4 76px,
      #a4a4a4 77px,
      transparent 78px,
      transparent 109px
    ),
    #303030;
  background-size: 109px 109px, 109px 109px, 100% 6px, 109px 109px, 109px 109px;
  background-position: 54px 55px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
}

.white-cross-dots {
  background: radial-gradient(black 3px, transparent 4px),
    radial-gradient(black 3px, transparent 4px),
    linear-gradient(#fff 4px, transparent 0),
    linear-gradient(
      45deg,
      transparent 74px,
      transparent 75px,
      #a4a4a4 75px,
      #a4a4a4 76px,
      transparent 77px,
      transparent 109px
    ),
    linear-gradient(
      -45deg,
      transparent 75px,
      transparent 76px,
      #a4a4a4 76px,
      #a4a4a4 77px,
      transparent 78px,
      transparent 109px
    ),
    #fff;
  background-size: 109px 109px, 109px 109px, 100% 6px, 109px 109px, 109px 109px;
  background-position: 54px 55px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
}

:root {
  --purple: #a95af3;
  --dusty-purple: #7d6391;
  --sakura: #5d998b;
  --trans: transparent;
}

.purple-quartz {
  /* background-color: #303030; */
  background-image: repeating-linear-gradient(
    -55deg,
    var(--trans) 0%,
    var(--trans) 35%,
    var(--salmon) 35%,
    var(--salmon) 48%,
    var(--purple) 48%,
    var(--purple) 55%,
    var(--lemon-green) 55%,
    var(--lemon-green) 65%,
    var(--trans) 65%,
    var(--trans) 100%
  );
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86vh;
  flex-direction: column;
}
